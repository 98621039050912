import { Delete, Edit, PlusOne } from '@mui/icons-material';
import { Accordion, AccordionDetails, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
// import ToolTipIcon from '../../icons/ToolTipIcon';
//import './LinkManagement.scss';
import useRemote from '../state/useRemote';


function LinkManagement(props) {

	const [links, setLinks] = useState([]);
	const [frontLinks, setFrontLinks] = useState([]);

	const [sectionEdit, setSectionEdit] = useState(null);
	const [linkEdit, setLinkEdit] = useState(null);
	const [linkCreate, setLinkCreate] = useState(null);

	const [editingObject, setEditingObject] = useState(null);

	const remote = useRemote();

	useEffect(() => {
		remote.get("links")
			.then(setLinks)
			.catch(error => console.error(error));
		/* remote.get("front-links")
			.then(setFrontLinks)
			.catch(error => console.error(error)); */
	},[]);

	const deleteSection = (id) => {
		console.log("Confirm deleting section and all its bits?", id);

	}

	const editSection = (id) => {
		const link = links.filter(l => l.id === id)[0];
		setEditingObject({"title":link.linkHeader, "id":id});
		setSectionEdit(id);
	}

	const editLink = (id) => {
		const link = links.flatMap(x => x.links).filter(l => l.id === id)[0];
		setEditingObject({"id":id, "title":link.description, "link":link.link});
		setLinkEdit(id);
	}

	const deleteLink = (id) => {
		remote.delete("links/"+id)
			.then(setLinks)
			.catch(error => console.error(error));
	}

	const createLink = (id) => {
		setEditingObject({"title":"", "link":""});
		setLinkCreate(id)
	} 

	const updateSectionTitle = () => {
		remote.put("link-section", {id: editingObject.id, linkHeader: editingObject.title})
			.then(setLinks)
			.catch(error => console.error(error));
		handleClose();
	}

	const updateLink = () => {
		remote.put("links/"+editingObject.id, {id: editingObject.id, description: editingObject.title, link: editingObject.link})
			.then(setLinks)
			.catch(error => console.error(error));
		handleClose();
	}

	const createLinkImpl = () => {
		remote.post("links/"+linkCreate, {description: editingObject.title, link: editingObject.link})
			.then(setLinks)
			.catch(error => console.error(error));
		handleClose();
	} 

	const handleChange = (e, which) => {
		editingObject[which] = e.target.value;
		setEditingObject({...editingObject});

	}

	const handleClose = () => {
		setSectionEdit(null);
		setLinkEdit(null);
		setLinkCreate(null);
		setEditingObject(null);
	}

	const drawerWidth = 240;
	
	return (
		<Box
				component="main"
				sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
			>
				<Toolbar />
		<Typography align="left">


			{/*<p>{filter === 'favourites' ? "Show all" : "Show favourites only"}</p>*/}
			{links.map(linkSection => <Paper key={linkSection.id} >
				
				<div class="d-flex justify-content-start link-section-edit header">
					<h3 >
					<Tooltip title="Edit"><IconButton onClick={() => editSection(linkSection.id)}><Edit/></IconButton></Tooltip>
					<Tooltip title="Delete"><IconButton onClick={() => deleteSection(linkSection.id)}><Delete/></IconButton></Tooltip>
					<Tooltip title="New Link"><IconButton onClick={() => createLink(linkSection.id)}><PlusOne/></IconButton></Tooltip>
					{linkSection.linkHeader} </h3>
					</div>
					{linkSection.links.map(link => <div key={"link"+link.id} class="d-flex justify-content-start link-section-edit"><p >
						<Tooltip title="Edit"><IconButton onClick={() => editLink(link.id)}><Edit/></IconButton></Tooltip>
						<Tooltip title="Delete"><IconButton onClick={() => deleteLink(link.id)}><Delete/></IconButton></Tooltip>
					{link.description}</p>
					</div>)}
				</Paper>)}
		</Typography>
		<Dialog open={sectionEdit} onClose={handleClose} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">Edit Link Section Title</DialogTitle>
			<DialogContent>
				<DialogContentText>
					This will update the title of the links section.  Users will need to refresh on their phones to see this update.
				</DialogContentText>
				<TextField
					autoFocus
					margin="dense"
					id="name"
					label="Title"
					type="text"
					fullWidth
					value={editingObject && editingObject.title}
					inputProps={{ maxLength: 90 }}
					onChange={(e) => handleChange(e, 'title')}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={updateSectionTitle} disabled={editingObject && editingObject.title.trim() === ''} color="primary">
					Update
				</Button>
			</DialogActions>
		</Dialog>
		<Dialog open={linkEdit} onClose={handleClose} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">Edit Link</DialogTitle>
			<DialogContent>
				<DialogContentText>
					This will update the details of the link.  Users will need to refresh on their phones to see this update.
				</DialogContentText>
				<TextField
					autoFocus
					margin="dense"
					id="name"
					label="Title"
					type="text"
					fullWidth
					value={editingObject && editingObject.title}
					inputProps={{ maxLength: 90 }}
					onChange={(e) => handleChange(e, 'title')}
				/>
				<TextField
					autoFocus
					margin="dense"
					id="name"
					label="Link"
					type="text"
					fullWidth
					value={editingObject && editingObject.link}
					inputProps={{ maxLength: 90 }}
					onChange={(e) => handleChange(e, 'link')}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={updateLink} disabled={editingObject && editingObject.link && (editingObject.title.trim() === '' || editingObject.link.trim() === '')} color="primary">
					Update
				</Button>
			</DialogActions>
		</Dialog>
		<Dialog open={linkCreate} onClose={handleClose} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">Edit Link</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Create a new link under this section.  Users will need to refresh on their phones to see this update.
				</DialogContentText>
				<TextField
					autoFocus
					margin="dense"
					id="name"
					label="Title"
					type="text"
					fullWidth
					value={editingObject && editingObject.title}
					inputProps={{ maxLength: 90 }}
					onChange={(e) => handleChange(e, 'title')}
				/>
				<TextField
					autoFocus
					margin="dense"
					id="name"
					label="Link"
					type="text"
					fullWidth
					value={editingObject && editingObject.link}
					inputProps={{ maxLength: 90 }}
					onChange={(e) => handleChange(e, 'link')}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={createLinkImpl} disabled={editingObject && editingObject.link && (editingObject.title.trim() === '' || editingObject.link.trim() === '')} color="primary">
					Update
				</Button>
			</DialogActions>
		</Dialog>
		</Box>
	);

	}

	
export default LinkManagement;