import * as React from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { UserContext } from '../UserContext';
import { Toolbar, Typography } from '@mui/material';
import useRemote from '../state/useRemote';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate, useParams } from 'react-router-dom';

const UserDetailsPage = () => {

	const [users, setUsers] = React.useState([])
	const {user} = React.useContext(UserContext);

	const [official, setOfficial] = React.useState(null);

	const {uuid} = useParams();
	const remote = useRemote();
	const navigate = useNavigate();

	React.useEffect(() => {
		remote.get(`official/${uuid}`).then(setOfficial).catch(() => navigate("/users"));
	}, [uuid]);


	const drawerWidth = 240;

	console.log("Official is ", official);


	return (
		<Box
				component="main"
				sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
			>
				<Toolbar />
				Got user {uuid}

			</Box>
	)

}

export default UserDetailsPage;