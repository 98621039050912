import * as React from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { UserContext } from '../UserContext';
import { Toolbar, Typography } from '@mui/material';

import useRemote from '../state/useRemote';
import ProvincesTable from '../components/ProvincesTable';

const AdminPage = () => {

	const {user} = React.useContext(UserContext);
	const [provinces, setProvinces] = React.useState([]);

	const drawerWidth = 240;

	const remote = useRemote();

	React.useEffect(() => {
		remote.get('provinces').then(setProvinces);
	}, [])

	return (
		<Box
				component="main"
				sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
			>
				<Toolbar />
				<Typography align="left" paragraph>
					Village courts admin functions to go here.
				</Typography>
				<Typography align="left" paragraph>
					Provinces
				</Typography>
				<ProvincesTable provinces={provinces}/>
				
			</Box>
	)

}

export default AdminPage;