import * as React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"

const ProvincesTable = ({provinces}) => {

	return (
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 650 }} aria-label="simple table">
				<TableHead style={{fontWeight:"bold"}}>
				<TableRow>
					<TableCell style={{fontWeight:1500}}>Province</TableCell>
					<TableCell style={{fontWeight:1500}} align="right">Officials</TableCell>
				</TableRow>
				</TableHead>
				<TableBody>
				{provinces.map((row) => (
					<TableRow
					key={row.name}
					sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
					>
					<TableCell component="th" scope="row">
						{row.name}
					</TableCell>
					<TableCell align="right">{row.calories}</TableCell>
					
					</TableRow>
				))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default ProvincesTable;