import { useEffect, useState } from "react";
import useRemote from "./useRemote";

function useAuth() {
	const [auth, setAuth] = useState(null);
	const [token, setToken] = useState(sessionStorage.getItem("villagecourts.auth.token"));

	

	// useEffect(() => {
	// 	remote.get("auth").then(() => setToken(token)).catch(() => {/*token expired, no-op*/});
	// }, []);

	const storeToken = (token) => {
		setToken(token);
		sessionStorage.setItem("villagecourts.auth.token, token");
	}
	
	const login = (deets) => {
		
	}

	const logout = () => {
		sessionStorage.removeItem("villagecourts.auth.token");
		setAuth(null);
	}

	const remote = useRemote({storeToken, logout});

	return {
		auth: () => auth,
		token: () => token,
		setToken: setToken,
		login: login,
		logout: logout
	}

}

export default useAuth;