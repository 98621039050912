import * as React from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { UserContext } from '../UserContext';
import { Alert, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, Grid, Snackbar, TextField, Toolbar, Typography } from '@mui/material';
import useRemote from '../state/useRemote';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import Select from 'react-select'
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate } from 'react-router-dom';

const style = {
	fab: {
		margin: 0,
		top: 'auto',
		right: 50,
		bottom: 50,
		left: 'auto',
		position: 'fixed',
	}
};

const idds = [
	{code: "PG", name: "Papua New Guinea", idd:"+675"},
	{code: "AU", name: "Australia", idd:"+61"},
	{code: "NZ", name: "New Zealand", idd:"+64"}
]

const UsersPage = () => {

	const [users, setUsers] = React.useState([])
	const {user} = React.useContext(UserContext);
	const [provinces, setProvinces] = React.useState([]);
	const [errorToast, setErrorToast] = React.useState(null);

	const [createUser, setCreateUser] = React.useState(false);
	const [createdUser, setCreatedUser] = React.useState({
		firstName: "",
		lastName: "",
		phone: {
			idd:"",
			number:""
		},
		email:"",
		provinceId:""
	})
	const [formValid, setFormValid] = React.useState(false);
	const navigate = useNavigate();

	const setBlankCreatedUser = () => {
		setCreatedUser({
			firstName: "",
			lastName: "",
			phone: {
				idd:"",
				number:""
			},
			email:"",
			provinceId:""
	
		})
		setFormValid(false);
	}

	const handleChange = (e, which) => {
		createdUser[which] = e.target.value;
		setCreatedUser({...createdUser});
		setFormValid(
			createdUser.firstName.trim() !== "" &&
			createdUser.lastName.trim() !== "")
	}

	const handleIddChange = (e, newValue) => {
		createdUser.phone.idd = newValue && newValue.idd || "";
		setCreatedUser({...createdUser});
	}

	const handleProvinceChange = (e, newValue) => {
		createdUser.provinceId = newValue && newValue.id || "";
		setCreatedUser({...createdUser});
	}

	const handleMobileChange = (e) => {
		createdUser.phone.number = e.target.value;
		setCreatedUser({...createdUser});
	}


	const handleClose = () => {
		setCreateUser(false);
		setBlankCreatedUser();
	}

	const remote = useRemote();

	React.useEffect(() => {
		remote.get("users").then((resp) => {
			if (!resp || resp.error) {
				//do nothing
			} else {
				setUsers(resp)
			}
		});
		remote.get("provinces").then((resp) => {
			if (!resp || resp.error) {
				//do nothing
			} else {
				setProvinces(resp)
			}
		});
	}, []);

	const doCreateUser = () => {
		remote.post("create-user", createdUser).then((response) => {
			console.log("Got a response", response);
			remote.get("users").then((resp) => {
				if (!resp || resp.error) {
					//do nothing
				} else {
					setUsers(resp)
				}
			});
		}).catch(e => setErrorToast(e))
		console.log("Doing a create user on ", createdUser)
	}

	const decodePhone = (json) => {
		try {
			const mobile = JSON.parse(json);
			return ("("+mobile.idd+") "+mobile.number);
		} catch (e) {
			return "No mobile"
		}
	}

	const decodeProvice = (id) => {
		if (!provinces || provinces.length === 0) {
			return "";
		}
		if (!id) {
			return "No province set";
		}
		try {
			return provinces.find(p => p.id === id.id).name;
		} catch (e) {
			console.log(e, id);
			return "No province set";
		}	
	}

	const drawerWidth = 240;


	return (
		<>
		<Box
				component="main"
				sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
			>
				<Toolbar />
				<Snackbar open={errorToast} autoHideDuration={6000} >
					<Alert severity="error" sx={{ width: '100%' }}>
					    {errorToast}
					</Alert>
				</Snackbar>
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={provinces.length === 0}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
				<TableContainer component={Paper}>
					<Table sx={{ minWidth: 650 }} aria-label="simple table">
						<TableHead>
						<TableRow>
							<TableCell>User</TableCell>
							<TableCell align="left">Phone number</TableCell>
							<TableCell align="left">Province</TableCell>
							<TableCell align="left">Type</TableCell>
							<TableCell align="left">Last accessed</TableCell>
						</TableRow>
						</TableHead>
						<TableBody>
						{users.map((row) => (
							<TableRow
							key={row.name}
							sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							onClick={() => navigate(`/users/${row.id}`)}
							>
							<TableCell component="th" scope="row">
								{row.name}
							</TableCell>
							<TableCell align="left">{decodePhone(row.mobile)}</TableCell>
							<TableCell align="left">{decodeProvice(row.province)}</TableCell>
							<TableCell align="right"></TableCell>
							<TableCell align="right"></TableCell>
							</TableRow>
						))}
						</TableBody>
					</Table>
				</TableContainer>

				
			</Box>
			<Fab color="primary" aria-label="add" style={style.fab} onClick={() => setCreateUser(true)}>
			<AddIcon />
			</Fab>
			<Dialog PaperProps={{style: {overflowY: 'visible'}}} open={createUser} onClose={handleClose} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">Create new Official</DialogTitle>
				<DialogContent style={{ overflowY: 'visible' }}>
					<DialogContentText>
							Create a new user who can use the Village Courts Mobile App.
					</DialogContentText>
					<Grid container spacing={2}>
					<Grid item xs={6}>
						<TextField
							autoFocus
							margin="dense"
							id="name"
							label="First Name"
							type="text"
							fullWidth
							value={createdUser && createdUser.firstName}
							inputProps={{ maxLength: 90 }}
							onChange={(e) => handleChange(e, 'firstName')}
						/>
  					</Grid>
  					<Grid item xs={6}>
						<TextField
							
							margin="dense"
							id="name"
							label="Last Name"
							type="text"
							fullWidth
							value={createdUser && createdUser.lastName}
							inputProps={{ maxLength: 90 }}
							onChange={(e) => handleChange(e, 'lastName')}
						/>
					</Grid>
				</Grid>
				<TextField
							
							margin="dense"
							id="name"
							label="Email"
							type="text"
							fullWidth
							value={createdUser && createdUser.email}
							inputProps={{ maxLength: 90 }}
							onChange={(e) => handleChange(e, 'email')}
						/>
				<Grid container spacing={2} style={{marginTop: "2px"}}>
				<Grid item xs={5}>
					<Autocomplete
						disablePortal
						id="idd-combo"
						options={idds}
						getOptionLabel={(option) => option.idd}
						renderInput={(params) => <TextField {...params} label="Idd" />}
						renderOption={(props, option) => (
							<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
							  <img
								loading="lazy"
								width="20"
								src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
								srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
								alt=""
							  />
							  {option.name} ({option.code}) +{option.idd}
							</Box>
						  )}
						onChange={handleIddChange}
						isOptionEqualToValue={(option, value) => option.code === value.code}
			  
				/>
  					</Grid>
					<Grid item xs={7}>
						<TextField
							id="name"
							label="Mobile"
							type="number"
							fullWidth
							value={createdUser && createdUser.phone.number}
							inputProps={{ maxLength: 90 }}
							onChange={handleMobileChange}
						/>
					</Grid>
				</Grid>
				<Autocomplete
					style={{marginTop:"15px"}}
					disablePortal
					id="combo-box-demo"
					options={provinces}
					sx={{ width: 300 }}
					getOptionLabel={(option) => option.name}
					renderInput={(params) => <TextField {...params} label="Province" />}
					onChange={handleProvinceChange}
					isOptionEqualToValue={(option, value) => option.id === value.id}

			  
				/>
				
				</DialogContent>
				<DialogActions>
					<Button onClick={doCreateUser} disabled={!createdUser || createdUser.firstName.trim() === ''} color="primary">
						Create
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)

}

export default UsersPage;