import { Button, Input, Paper } from '@mui/material';
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { styled } from '@mui/material/styles';
import home from '../assets/home.png' 

import { Outlet } from 'react-router-dom';
import { Lock } from '@mui/icons-material';
import useRemote from '../state/useRemote';

const StyledPaper = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: theme.spacing(2),
	maxWidth: 400,
	color: theme.palette.text.primary,
  }));

const LoginWrapper = ({setUser}) => {

	const [showPassword, setShowPassword] = useState(false);
	const [login, setLogin] = useState("");
	const [password, setPassword] = useState(""); 
	const [error, setError] = useState("");

	const remote = useRemote();

	const doLogin = () => {
		setError("");
		console.log("Doing login, setUser is ", setUser);
		remote
			.post("login", {username:login, password:password})
			.then((user) => {
				console.log('got response', user);
				setUser(user)
			})
			.catch(e => {
				console.log("Caught error ", e);
				setError("Invalid username or password")
			});
	}

	return (
		<Box sx={{ flexGrow: 1, overflow: 'hidden', px: 3 }} style={{backgroundColor:'#752306', height:'100vh'}}>
			<img src={home} style={{width:'500px'}}/>
      <StyledPaper
        sx={{
          my: 1,
          mx: 'auto',
          p: 2,
        }}
      >
		  
			
      <h1>Login</h1>
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
        <TextField id="input-with-sx" label="Username" variant="standard" value={login} onChange={(e) => setLogin(e.target.value)} />
      </Box>
	  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <Lock sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
        <TextField 
			id="password" 
			label="Password" 
			variant="standard"
			type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            
			/>
			<IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={(event) => event.preventDefault()}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
      </Box>
	  {error && <div style={{marginTop:'20px', fontStyle:'italic'}}>{error}</div>}
	  <Button variant="contained" style={{marginTop:'20px'}} onClick={doLogin}>Login</Button>

	</StyledPaper>
	</Box>
	);
}

export default LoginWrapper;