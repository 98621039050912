import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Wrapper from './pages/Wrapper';
import useAuth from './state/useAuth';
import LoginWrapper from './login/LoginWrapper';
import {UserContext} from './UserContext';
import { useEffect, useRef, useState } from 'react';
import useRemote from './state/useRemote';
import AdminPage from './pages/AdminPage';
import UserDetailsPage from './pages/UserDetailsPage';
import UsersPage from './pages/UsersPage';
import LinksPage from './pages/LinksPage';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LinkManagement from './pages/LinkManagement';

function App() {

	const [user, setUser] = useState(null)
	const [loading, setLoading] = useState(true)

	const remote = useRemote();
	const isFirstRender = useRef(true)

	useEffect(() => {
		remote
			.get("user")
			.then((u) => {
				setUser(u);
				//setLoading(false);
			})
			.catch((e) => {setLoading(false);console.log("Error",e)});
	},[]);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false 
			return;
		}
		setLoading(false);
	}, [user])

	const logout = () => {
		remote.logout();
	}

	if (loading) {
		return <Backdrop
		sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
		open={loading}
	>
		<CircularProgress color="inherit" />
	</Backdrop>
	}

	return (
		<UserContext.Provider value={{user,setUser}}>
			<div className="App">
				<div id="master-container">
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={loading}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
					<BrowserRouter>
						{/* Pages */}
						<Routes>
							{user ? 
								<Route path="/" element={<Wrapper logout={logout} />}>
									<Route index element={<AdminPage />} />
									<Route path="users" element={<UsersPage />} />
									<Route path="users/:uuid" element={<UserDetailsPage />} />
									<Route path="links" element={<LinkManagement />} />
									<Route path="training" element={<AdminPage />} />
									<Route path="account" element={<AdminPage />} />
								</Route> 
								: 
								<Route path="*" element={<LoginWrapper setUser={setUser}/>}></Route>
							}						
						</Routes>
					</BrowserRouter>
				</div>
			</div>
		</UserContext.Provider>
	);
}

export default App;
