import { useContext, useState } from "react";
import { UserContext } from "../UserContext";
import useAuth from "./useAuth"


const useRemote = (props) => {

	const [token, setToken] = useState(sessionStorage.getItem("villagecourts.auth.token"));

	const {user, setUser} = useContext(UserContext);

	const storeToken = (token) => {
		console.log("Storing token ", token);
		setToken(token);
		sessionStorage.setItem("villagecourts.auth.token", token);
	}
	
	const base = "api/admin/";//http://localhost:8080/admin/";
	
	const get = (uri, options) => {
		let headers = createTokenHeader();
		options = options || {};
        var url = base + uri;
		return fetch(url, headers).then(resp => {return handleResponse(resp, options)});
	}

	const handleResponse = (resp, options) => {
		if (resp.ok) {
			console.log("Response is ok");
			console.log(resp);
			console.log(resp.headers);
			console.log(resp.headers.get("X-VCA-Set-Token"));
			storeToken(resp.headers.get("X-VCA-Set-Token"));
			return resp.json();
		} else {
			if (resp.status === 418 || resp.status === 401) {
                handleSessionTimeout();
				return new Promise((resolve, reject) => reject(resp));
			}
		}
	}

	const logout = () => {
		handleSessionTimeout();
	}

	const handleSessionTimeout = () => {
		console.log("Session timeout, logout");
		storeToken(null);
		if (setUser && typeof setUser === 'function') {
			setUser(null);
		} else {
			console.warn("can't set user as no context");
		}
	}

	const createTokenHeader = () => {
        if (!token) {
            return {
                headers: {}
            };
        }
        return {
            headers: {
                "X-VillageCourtsAdmin-Session-Token": token
            }
        };
    }

	const send = (method, uri, content, options) => {
		let request = createTokenHeader();
        options = options || {};
        var url = base + uri;

		request.headers["Content-Type"] = "application/json; charset=utf-8";
		Object.assign(request, {
            method: method,
            body: JSON.stringify(content),
            credentials: "omit"
        });
		return timeout(5000, fetch(url, request))
			.then((resp)=>{
				return handleResponse(resp, options);
			});
	}

	const timeout = (millis, promise) => {
        return new Promise(function(resolve, reject) {
            setTimeout(function() {
                reject(new Error("timeout"))
            }, millis);
            promise.then(resolve, reject)
        })
    }

	return {
		get: get,
		post: (uri, content, options) => send("post", uri, content, options),
		put: (uri, content, options) => send("put", uri, content, options),
		delete: (uri, content, options) => send("delete", uri, content, options),
		logout: () => handleSessionTimeout()
	}
}

export default useRemote;